export default {
  props: {
    arr: {
      type: Object,
    },
  },
  name: "WarehouseDetailSearchbarLayout",
  data() {
    return {
      //VARIABLES
      text:'',
      tabWarehouse: null,
      titleName: "",
    };
  },
  beforeMount() {
    this.texts = FILE.warehouseTexts[this.selectLanguage];
},
  methods: {
    setDialogDelete: function () {
      this.$store.commit("setDialogDelete", {
        active: true,
        title: this.texts.warehousedetail.textTitleDelete,
        api: `${URI}/api/v1/${this.selectLanguage}/customers/enterprises/${this.$store.state.sEnterpriseId}/warehouses/${this.arr.sVendorWarehouseId}`,
        redirect: "/warehouses",
      });
    },
  },
  computed: {
    selectLanguage: function () {
      return this.$store.state.language;
    },
  },
  watch: {
    arr: function () {
      if (this.arr) {
        this.titleName = this.arr.oWarehouse.sName;
      }
    },
    selectLanguage: function () {
      if (this.selectLanguage) {
        this.texts = FILE.warehouseTexts[this.selectLanguage];
      }
    },
  },
};